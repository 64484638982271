import { useState } from "react";
import styled from "styled-components";

import CheckBox from "components/CheckBox";
import { Row, FieldValueWithBorder } from "components/LayoutParts";
import Loading from "components/Loading";
import SearchForm from "components/SearchForm";

const Area = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  background-color: white;
  padding: 24px;
`;

const Description = styled.span`
  margin-right: 5px;
`;

const Table = styled.div`
  width: 100%;
  border-top: solid 1px #d6d3d0;
  border-right: none;
  margin-top: 20px;
`;

const TableRow = styled(FieldValueWithBorder)`
  color: #23221f;
`;

const SearchArea = ({
  searchKeyword,
  onSearchChange,
  spacesWithProperty,
  setSelectedPropertyIds,
  isLoading,
}: {
  searchKeyword: string;
  spacesWithProperty: SpaceWithProperty[];
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setSelectedPropertyIds: React.Dispatch<React.SetStateAction<number[]>>;
  isLoading: boolean;
}) => {
  const [checkedStates, setCheckedStates] = useState<Record<string, boolean>>({});

  const handleCheckboxChange = (id: string) => {
    setCheckedStates((prev) => ({ ...prev, [id]: !prev[id] }));
    setSelectedPropertyIds((prev) => {
      const isChecked = !checkedStates[id];
      const numericId = parseInt(id, 10);
      if (isChecked) {
        return [...prev, numericId];
      } else {
        return prev.filter((propertyId) => propertyId !== numericId);
      }
    });
  };

  const filteringSpaceByKeyword = (spaces: SpaceWithProperty[], keyword: string) => {
    return spaces.filter((space) => {
      return (
        space.property.name.toLowerCase().includes(keyword.toLowerCase()) ||
        space.property.address.toLowerCase().includes(keyword.toLowerCase())
      );
    });
  };

  return (
    <>
      <Area>
        <Description>対象の限定区画を検索（駐車場名、住所）</Description>
        <SearchForm width="350px" value={searchKeyword} placeholder="検索" onChange={onSearchChange} />
        {isLoading ? (
          <Area>
            <Loading size="s" />
          </Area>
        ) : (
          searchKeyword && (
            <Table>
              {filteringSpaceByKeyword(spacesWithProperty, searchKeyword).map((propertySpace: SpaceWithProperty) => (
                <Row key={propertySpace.id}>
                  <FieldValueWithBorder flex={0.2}>
                    <CheckBox
                      id={propertySpace.id?.toString() || ""}
                      isChecked={!!checkedStates[propertySpace.id?.toString() || ""]}
                      setIsChecked={() => handleCheckboxChange(propertySpace.id?.toString() || "")}
                      label=""
                    />
                  </FieldValueWithBorder>
                  <TableRow flex={0.5}>{propertySpace.propertyId}</TableRow>
                  <TableRow flex={1.5}>{propertySpace.property.name}</TableRow>
                  <TableRow flex={2}>{propertySpace.property.address}</TableRow>
                  <TableRow flex={0.5}>{propertySpace.id}</TableRow>
                  <TableRow flex={1}>{propertySpace.name}</TableRow>
                </Row>
              ))}
            </Table>
          )
        )}
      </Area>
    </>
  );
};

export default SearchArea;
