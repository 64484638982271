import useClient from "hooks/atPort/useClient";

const useDeleteUser = () => {
  const { client } = useClient();

  const deleteUser = async (id: string | undefined) => {
    if (!id) return;

    const { status } = await client({
      method: "DELETE",
      url: `/user/${id}`,
    });
    return status;
  };

  return { deleteUser };
};

export default useDeleteUser;
