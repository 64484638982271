import { UserModalConfig } from "components/UserModal";

export const INITIAL_MODAL_CONFIG: UserModalConfig = {
  onClickOutside: () => {
    return;
  },
  subtitle: "完了しました",
  main: {
    buttonType: "secondary",
    onClick: () => {
      return;
    },
    label: "閉じる",
  },
};

export const CUSTOMER_REACTIVATE_MODAL_CONFIG = ({
  positive,
  negative,
}: {
  positive: () => void;
  negative: () => void;
}): UserModalConfig => ({
  onClickOutside: negative,
  subtitle: "利用再開しますか？",
  main: {
    buttonType: "primary",
    onClick: positive,
    label: "利用再開する",
  },
  sub: {
    label: "閉じる",
    buttonType: "secondary",
    onClick: negative,
  },
});

export const CUSTOMER_DELETE_MODAL_CONFIG = ({
  positive,
  negative,
}: {
  positive: () => void;
  negative: () => void;
}): UserModalConfig => ({
  onClickOutside: negative,
  subtitle: "削除しますか？",
  main: {
    buttonType: "primary",
    onClick: positive,
    label: "削除する",
  },
  sub: {
    label: "閉じる",
    buttonType: "secondary",
    onClick: negative,
  },
});

export const CUSTOMER_SUSPEND_MODAL_CONFIG = ({
  positive,
  negative,
}: {
  positive: () => void;
  negative: () => void;
}): UserModalConfig => ({
  onClickOutside: negative,
  subtitle: "利用停止しますか？",
  main: {
    buttonType: "primary",
    onClick: positive,
    label: "利用停止する",
  },
  sub: {
    label: "閉じる",
    buttonType: "secondary",
    onClick: negative,
  },
});

export const SUCCEED_MODAL_CONFIG = ({
  onClose,
  message,
}: {
  onClose: () => void;
  message: string;
}): UserModalConfig => {
  return {
    onClickOutside: onClose,
    subtitle: message,
    main: {
      buttonType: "primary",
      onClick: onClose,
      label: "閉じる",
    },
  };
};

export const FAILED_MODAL_CONFIG = (onClose: () => void): UserModalConfig => {
  return {
    onClickOutside: onClose,
    subtitle: "送信に失敗しました",
    main: {
      buttonType: "secondary",
      onClick: onClose,
      label: "閉じる",
    },
    errorMessage: "時間をおいてもう一度お試しください",
  };
};
