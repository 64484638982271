// 参考 pitport ユーザステータス  0 : "無効" , 1 : "-" , 2 : "承認待ち", 3 : "承認NG", 4 : "利用停止" 5 : "削除済み", 6 : "";

/**
 * ユーザーのステータスを削除に変更できるかどうかを判定する
 */
export const isCanUserRemove = (status: PitportUserStatusNumber): boolean => {
  return [1, 3, 4].includes(status);
};

/**
 * ユーザーのステータスを利用中(-)に変更できるかどうかを判定する
 * 削除済みのユーザーは変更できない
 */
export const isCanUserReactivateExcludeDeletedStatus = (status: PitportUserStatusNumber): boolean => {
  return [0, 4].includes(status);
};

/**
 * ユーザーのステータスを利用中(-)に変更できるかどうかを判定する
 * 削除済みのユーザーも利用中に変更できる
 */
export const isCanUserReactivate = (status: PitportUserStatusNumber): boolean => {
  return [0, 4, 5].includes(status);
};
