import { useCallback } from "react";

import { usePitPortClient } from "hooks/api/usePitPortClient";
import { useSwr } from "hooks/useSwr";
import { formatQueryParams } from "utils/stringUtils";

export const useFindCustomers = ({
  keyword,
  orderBy,
  orderType,
  pageIndex,
  status,
  pageSize = 100,
}: {
  pageIndex: number;
  status: PitPortUserDisplayFilterStatus;
  orderBy: SortOption<SortByPitPortCustomer>["sortByColumn"];
  orderType: SortOption<SortByPitPortCustomer>["direction"];
  keyword: string;
  pageSize?: number;
}) => {
  const { data, error } = useSwr<PitPortCustomersResponse>(
    `/customer?${formatQueryParams({
      pageIndex,
      pageSize,
      status,
      orderBy,
      orderType,
      keyword,
    })}`
  );
  return { data: data, error };
};

export const useFindCustomer = (id: number) => {
  const { data, error, mutate } = useSwr<{ data: PitPortCustomerDetail }>(`/customer/${id}`);

  return { data: data?.data, error, mutate };
};

export const useReactivatePitPortCustomer = () => {
  const { client } = usePitPortClient();
  const reactivateCustomer = useCallback(
    async (userId: number) => {
      const { status } = await client({
        method: "put",
        url: `/customer/${userId}/reactivate`,
      });
      return status;
    },
    [client]
  );
  return { reactivateCustomer };
};

export const useSuspendPitPortCustomer = () => {
  const { client } = usePitPortClient();
  const suspendCustomer = useCallback(
    async (userId: number) => {
      const { status } = await client({
        method: "put",
        url: `/customer/${userId}/suspend`,
      });
      return status;
    },
    [client]
  );
  return { suspendCustomer };
};

export const useRemovePitPortCustomer = () => {
  const { client } = usePitPortClient();
  const removeCustomer = useCallback(
    async (userId: number) => {
      const { status } = await client({
        method: "delete",
        url: `/customer/${userId}`,
      });
      return status;
    },
    [client]
  );
  return { removeCustomer };
};
