/**
 *numberPlateオブジェクトより表示用の文字列を生成する関数
 * @example numberPlateDisplayValue({number: "1", region: "地域1", hiragana: "ひらがな1", regionCode: "地域コード1"}) → "1 地域1 ひらがな1 地域コード1"
 */
export const createNumberPlateDisplayValue = (numberPlate: NumberPlate | null): string => {
  return numberPlate
    ? `${numberPlate.number} ${numberPlate.region} ${numberPlate.hiragana} ${numberPlate.regionCode}`
    : "-";
};

/**
 *numberPlateオブジェクトよりnumberPlate配列を生成する関数
 */
export const createNumberPlateArrayFromObject = (numberPlate: NumberPlate | null): string[] => {
  return [
    numberPlate?.number ?? "",
    numberPlate?.region ?? "",
    numberPlate?.hiragana ?? "",
    numberPlate?.regionCode ?? "",
  ];
};
