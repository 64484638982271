import { useNavigate } from "react-router-dom";

import { Table, Heading, Row, FieldValue, FieldName } from "components/LayoutParts";
import Pagination from "components/Pagination";
import SortButton from "components/SortButton";
import { createNumberPlateDisplayValue } from "utils/numberPlateUtil";
import { switchUserStatusDisplayValue } from "utils/statusSwitchUtil";

const userTableColumn = [
  {
    label: "ID",
    sortKey: "id",
    flex: 8,
  },
  {
    label: "名前",
    sortKey: "name",
    flex: 13,
  },
  {
    label: "携帯番号",
    sortKey: "phoneNumber",
    flex: 15,
  },
  {
    label: "メールアドレス",
    sortKey: "email",
    flex: 19,
  },
  {
    label: "ナンバー",
    sortKey: "numberPlate",
    flex: 19,
  },
  {
    label: "住所",
    sortKey: "address",
    flex: 19,
  },
  { label: "状態", sortKey: "status", flex: 12 },
] as const;

const DataTable = ({
  userList,
  totalPages,
  pageIndex,
  sortOption,
  setPageIndex,
  setSortOption,
}: {
  userList: PitPortCustomer[];
  totalPages: number;
  pageIndex: number;
  sortOption: SortOption<SortByPitPortCustomer>;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
  setSortOption: React.Dispatch<React.SetStateAction<SortOption<SortByPitPortCustomer>>>;
  searchKeyword: string;
  selectedFilter: PitPortUserDisplayFilterStatus;
}) => {
  const navigate = useNavigate();
  const onClickRow = (userId: number) => navigate(`/pit_port/customer/${userId}`);

  return (
    <>
      <Table>
        <Heading>
          {userTableColumn.map((column) => (
            <FieldName key={column.label} flex={column.flex} isSort={sortOption.sortByColumn === column.sortKey}>
              {column.label}
              <SortButton currentSortOption={sortOption} sortByKey={column.sortKey} setSortOption={setSortOption} />
            </FieldName>
          ))}
        </Heading>
        {userList.map((user) => (
          <Row key={user.id} onClick={() => onClickRow(user.id)}>
            <FieldValue flex={userTableColumn[0].flex}>{user.id}</FieldValue>
            <FieldValue flex={userTableColumn[1].flex}>{user.lastName + user.firstName}</FieldValue>
            <FieldValue flex={userTableColumn[2].flex}>{user.phoneNumber}</FieldValue>
            <FieldValue flex={userTableColumn[3].flex}>{user.email}</FieldValue>
            <FieldValue flex={userTableColumn[4].flex}>{createNumberPlateDisplayValue(user.numberPlate)}</FieldValue>
            <FieldValue flex={userTableColumn[5].flex}>{user.customer.address}</FieldValue>
            <FieldValue flex={userTableColumn[6].flex}>{switchUserStatusDisplayValue(user.status)}</FieldValue>
          </Row>
        ))}
      </Table>
      <Pagination totalPages={totalPages} pageIndex={pageIndex} setPageIndex={setPageIndex} />
    </>
  );
};

export default DataTable;
