import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import Breadcrumb from "components/Breadcrumb";
import Button from "components/Button";
import { FormRow } from "components/LayoutParts";
import Loading from "components/Loading";
import TextForm from "components/TextForm";
import UserModal, { UserModalConfig } from "components/UserModal";
import color from "constants/color";
import font from "constants/font";
import useDeleteUser from "hooks/atPort/user/useDeleteUser";
import useFindById from "hooks/atPort/user/useFindById";
import useUpdateUser from "hooks/atPort/user/useUpdateUser";
import { linkSalesforceAccount, linkSalesforceContact } from "pages/AtPort/utils";

// ページの親要素
export const Board = styled.div`
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  position: relative;
`;
// ページタイトルのrow
export const TitleArea = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0px 0 10px 0;
  align-items: center;
  width: 100%;
`;

const ContentArea = styled.div`
  padding: 20px 48px;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid ${color.border};
  background-color: ${color.white};
`;
const Label = styled.div`
  min-width: 120px;
  margin: 0 5px;
`;
const Badge = styled.div`
  margin: 0 5px;
  padding: 0 5px;
  min-width: 32px;
  background-color: ${color.attention};
  color: ${color.white};
  font-size: 12px;
  text-align: center;
`;
const ButtonWrap = styled.div`
  width: 100%;
  margin-left: 24px;
`;
const CancelWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;
const FooterArea = styled.div`
  position: relative;
  bottom: 0;
  padding-top: 16px;
  border-top: 1px solid ${color.border};
  display: flex;
  flex-direction: row;
  background-color: ${color.background};
`;
const SaveWrap = styled(CancelWrap)`
  align-items: center;
  justify-content: flex-end;
`;
const Annotation = styled.span`
  font-size: ${font.size12};
  color: ${color.text};
  margin-right: 16px;
`;
const DeleteWrap = styled.div`
  display: flex;
  flex: auto;
  justify-content: flex-end;
`;

const EditUser = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, error, mutate } = useFindById(id);
  const { update } = useUpdateUser();
  const { deleteUser } = useDeleteUser();

  const [isModalShow, setIsModalShow] = useState(false);
  const [ModalConfig, setModalConfig] = useState<UserModalConfig>({
    onClickOutside: () => {
      return;
    },
    subtitle: "完了しました",
    main: {
      buttonType: "secondary",
      onClick: () => {
        return;
      },
      label: "閉じる",
    },
  });

  const hideModal = useCallback(() => setIsModalShow(false), []);
  const showModal = useCallback((config: UserModalConfig) => {
    setModalConfig(config);
    setIsModalShow(true);
  }, []);
  const [originalUser, setOriginalUser] = useState<SalesforceUser | undefined>(undefined);

  // フォームの値
  const [contactId, setContactId] = useState(data?.contactId ?? "");
  const [companyName, setCompanyName] = useState(data?.companyName ?? "");
  const [accountId, setAccountId] = useState(data?.accountId ?? "");
  const [email, setEmail] = useState(data?.email ?? "");

  // バリデーション
  const isErrorContactId = useMemo(() => {
    return contactId === "" || !/^[a-zA-Z0-9]+$/.test(contactId);
  }, [contactId]);
  const isErrorCompanyName = useMemo(() => {
    return companyName === "";
  }, [companyName]);
  const isErrorAccountId = useMemo(() => {
    return accountId === "" || !/^[a-zA-Z0-9]+$/.test(accountId);
  }, [accountId]);
  const isErrorEmail = useMemo(() => {
    return email === "";
  }, [email]);
  const isEdit = useMemo(() => {
    return (
      originalUser?.accountId !== accountId ||
      originalUser?.contactId !== contactId ||
      originalUser?.email !== email ||
      originalUser?.companyName !== companyName
    );
  }, [originalUser, accountId, contactId, email, companyName]);
  const canSubmit = useMemo(() => {
    return !isErrorContactId && !isErrorCompanyName && !isErrorAccountId && !isErrorEmail && isEdit;
  }, [isErrorContactId, isErrorCompanyName, isErrorAccountId, isErrorEmail, isEdit]);

  const onChangeContactId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContactId(e.target.value);
  };
  const onChangeCompanyName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyName(e.target.value);
  };
  const onChangeAccountId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccountId(e.target.value);
  };
  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const onClickDeleteButton = useCallback(async () => {
    const isConfirmed = window.confirm("本当に削除してよろしいですか？");

    if (!isConfirmed) {
      return;
    }

    const status = await deleteUser(id).catch(() => {
      showModal({
        onClickOutside: hideModal,
        subtitle: "送信に失敗しました",
        main: {
          buttonType: "secondary",
          onClick: hideModal,
          label: "閉じる",
        },
        errorMessage: "時間をおいてもう一度お試しください",
      });
    });

    if (status === 200) {
      showModal({
        onClickOutside: hideModal,
        subtitle: "削除しました",
        main: {
          buttonType: "secondary",
          onClick: () => {
            hideModal();
            navigate("/at_port/user");
          },
          label: "閉じる",
        },
      });
    }
  }, [deleteUser, hideModal, id, navigate, showModal]);

  const onSave = useCallback(async () => {
    const status = await update(id, {
      contactId: contactId,
      companyName: companyName,
      accountId: accountId,
      email: email,
    }).catch(() => {
      showModal({
        onClickOutside: hideModal,
        subtitle: "送信に失敗しました",
        main: {
          buttonType: "secondary",
          onClick: hideModal,
          label: "閉じる",
        },
        errorMessage: "時間をおいてもう一度お試しください",
      });
    });

    if (status == 200) {
      showModal({
        onClickOutside: hideModal,
        subtitle: "変更内容を保存しました",
        main: {
          buttonType: "secondary",
          onClick: () => {
            hideModal();
            navigate("/at_port/user");
          },
          label: "閉じる",
        },
      });
    }
  }, [update, id, contactId, companyName, accountId, email, showModal, hideModal, navigate]);

  useEffect(() => {
    if (data != null) {
      setOriginalUser(data);
      setContactId(data.contactId);
      setCompanyName(data.companyName);
      setAccountId(data.accountId);
      setEmail(data.email);
    }

    if (error != null) {
      showModal({
        onClickOutside: hideModal,
        subtitle: "送信に失敗しました",
        main: {
          buttonType: "secondary",
          onClick: hideModal,
          label: "閉じる",
        },
        errorMessage: "時間をおいてもう一度お試しください",
      });
    }
  }, [data, error, hideModal, mutate, showModal]);

  return (
    <Board>
      <TitleArea>
        <Breadcrumb
          currentPageName="アカウントを編集"
          breadcrumbItems={[{ pageName: "アカウント一覧", onClick: () => navigate("/at_port/user") }]}
        />
        <DeleteWrap>
          <Button label="このユーザを削除" onClick={onClickDeleteButton} type="danger" />
        </DeleteWrap>
      </TitleArea>
      {data == null ? (
        <Loading />
      ) : (
        <ContentArea>
          <FormRow>
            <Badge>必須</Badge>
            <Label>顧客ID</Label>
            <TextForm
              type="text"
              width="100%"
              wrapWidth="100%"
              value={accountId}
              placeholder="U3dV7Nmcyl"
              required
              onChange={onChangeAccountId}
              isError={isErrorAccountId}
              errorMessage="顧客IDを半角英数字で入力してください。"
            />
            <ButtonWrap>
              <Button type="secondary" label="存在チェック" onClick={() => linkSalesforceAccount(accountId)} />
            </ButtonWrap>
          </FormRow>
          <FormRow>
            <Badge>必須</Badge>
            <Label>顧客名</Label>
            <TextForm
              type="text"
              width="100%"
              wrapWidth="100%"
              value={companyName}
              placeholder="U3dV7Nmcyl"
              required
              onChange={onChangeCompanyName}
              isError={isErrorCompanyName}
              errorMessage="顧客名を入力してください。"
            />
          </FormRow>
          <FormRow>
            <Badge>必須</Badge>
            <Label>担当者ID</Label>
            <TextForm
              type="text"
              width="100%"
              wrapWidth="100%"
              value={contactId}
              placeholder="U3dV7Nmcyl"
              required
              onChange={onChangeContactId}
              isError={isErrorContactId}
              errorMessage="担当者IDを半角英数字で入力してください。"
            />
            <ButtonWrap>
              <Button type="secondary" label="存在チェック" onClick={() => linkSalesforceContact(contactId)} />
            </ButtonWrap>
          </FormRow>
          <FormRow>
            <Badge>必須</Badge>
            <Label>メールアドレス</Label>
            <TextForm
              type="text"
              width="100%"
              wrapWidth="100%"
              value={email}
              placeholder="U3dV7Nmcyl"
              required
              onChange={onChangeEmail}
              isError={isErrorEmail}
              errorMessage="メールアドレスを入力してください。"
            />
          </FormRow>
        </ContentArea>
      )}
      <FooterArea>
        <CancelWrap>
          <Button type="secondary" onClick={() => navigate("/at_port/user")} label="キャンセル" width="160px" />
        </CancelWrap>
        <SaveWrap>
          <Annotation>保存するまで変更内容は反映されません</Annotation>
          <Button type="primary" onClick={() => onSave()} label="変更内容を保存" disabled={!canSubmit} width="160px" />
        </SaveWrap>
      </FooterArea>
      <UserModal isVisible={isModalShow} config={ModalConfig}></UserModal>
    </Board>
  );
};

export default EditUser;
