import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import Breadcrumb from "components/Breadcrumb";
import Button from "components/Button";
import DeletedAlert from "components/DeletedAlert";
import { Board, ContentArea, FooterArea, FormRow, TitleArea } from "components/LayoutParts";
import UserModal, { UserModalConfig } from "components/UserModal";
import {
  useFindCustomer,
  useReactivatePitPortCustomer,
  useRemovePitPortCustomer,
  useSuspendPitPortCustomer,
} from "pages/PitPort/Customer/api";
import {
  FAILED_MODAL_CONFIG,
  INITIAL_MODAL_CONFIG,
  SUCCEED_MODAL_CONFIG,
  CUSTOMER_DELETE_MODAL_CONFIG,
  CUSTOMER_REACTIVATE_MODAL_CONFIG,
  CUSTOMER_SUSPEND_MODAL_CONFIG,
} from "pages/PitPort/Customer/modalTemplate";
import { isCanUserReactivateExcludeDeletedStatus, isCanUserRemove } from "pages/PitPort/util/changeUserStatusHelper";
import { createNumberPlateArrayFromObject } from "utils/numberPlateUtil";

const CancelWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;

const SaveWrap = styled(CancelWrap)`
  align-items: center;
  justify-content: flex-end;
`;
const DeleteWrap = styled.div`
  display: flex;
  flex: auto;
  justify-content: flex-end;
`;
const ButtonsWrap = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  gap: 12px;
  right: 32px;
`;
const Label = styled.div`
  min-width: 120px;
  margin: 2px 5px;
`;
const ItemWrap = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding-left: 40px;
`;
const NumberPlateWrap = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
  padding-left: 40px;
`;
const Item = styled.div`
  padding-left: 40px;
`;

const InnerItem = styled.div``;

const ContentWrap = styled.div`
  flex: 1;
`;

const EditCustomer = () => {
  const [isModalShow, setIsModalShow] = useState(false);
  const [ModalConfig, setModalConfig] = useState<UserModalConfig>(INITIAL_MODAL_CONFIG);

  const navigate = useNavigate();
  const { id } = useParams();

  const { data: customer, mutate } = useFindCustomer(Number(id));

  const { removeCustomer } = useRemovePitPortCustomer();
  const { reactivateCustomer } = useReactivatePitPortCustomer();
  const { suspendCustomer } = useSuspendPitPortCustomer();

  // 利用停止する
  const handleCustomerSuspend = async () => {
    const status = await suspendCustomer(Number(id));
    if (status === 200) {
      setModalConfig(SUCCEED_MODAL_CONFIG({ message: "利用停止しました", onClose: () => setIsModalShow(false) }));
      mutate();
    } else {
      setModalConfig(FAILED_MODAL_CONFIG(() => setIsModalShow(false)));
    }
    setIsModalShow(true);
  };

  // 削除する
  const handleCustomerDelete = async () => {
    const status = await removeCustomer(Number(id));
    if (status === 200) {
      setModalConfig(SUCCEED_MODAL_CONFIG({ message: "削除しました", onClose: () => setIsModalShow(false) }));
      mutate();
    } else {
      setModalConfig(FAILED_MODAL_CONFIG(() => setIsModalShow(false)));
    }
    setIsModalShow(true);
  };

  //利用再開する
  const handleCustomerReactivate = async () => {
    const status = await reactivateCustomer(Number(id));
    if (status === 200) {
      setModalConfig(SUCCEED_MODAL_CONFIG({ message: "利用再開しました", onClose: () => setIsModalShow(false) }));
      mutate();
    } else {
      setModalConfig(FAILED_MODAL_CONFIG(() => setIsModalShow(false)));
    }
    setIsModalShow(true);
  };

  const showSuspendModal = () => {
    setModalConfig(
      CUSTOMER_SUSPEND_MODAL_CONFIG({ positive: handleCustomerSuspend, negative: () => setIsModalShow(false) })
    );
    setIsModalShow(true);
  };

  const showRemoveModal = () => {
    setModalConfig(
      CUSTOMER_DELETE_MODAL_CONFIG({ positive: handleCustomerDelete, negative: () => setIsModalShow(false) })
    );
    setIsModalShow(true);
  };

  const showReactivateModal = () => {
    setModalConfig(
      CUSTOMER_REACTIVATE_MODAL_CONFIG({ positive: handleCustomerReactivate, negative: () => setIsModalShow(false) })
    );
    setIsModalShow(true);
  };

  if (!customer) return null;

  return (
    <Board>
      <TitleArea>
        <Breadcrumb
          currentPageName="一般ユーザを編集"
          breadcrumbItems={[{ pageName: "一般ユーザ", onClick: () => navigate("/pit_port/customer") }]}
        />
        <ButtonsWrap>
          {customer.status === 1 && (
            <DeleteWrap>
              <Button label="このユーザを利用停止" onClick={showSuspendModal} type="danger" width="180px" />
            </DeleteWrap>
          )}
          {isCanUserRemove(customer.status as PitportUserStatusNumber) && (
            <DeleteWrap>
              <Button label="このユーザを削除" onClick={showRemoveModal} type="danger" />
            </DeleteWrap>
          )}
          {isCanUserReactivateExcludeDeletedStatus(customer.status as PitportUserStatusNumber) && (
            <SaveWrap>
              <Button label="このユーザを利用再開" type="primary" onClick={showReactivateModal} width="180px" />
            </SaveWrap>
          )}
        </ButtonsWrap>
      </TitleArea>
      {customer.status === 5 && <DeletedAlert message="このユーザは削除済みです" />}
      <ContentWrap>
        <ContentArea>
          <FormRow>
            <Label>名前</Label>
            <ItemWrap>
              <InnerItem>{customer.lastName}</InnerItem>
              <InnerItem>{customer.firstName}</InnerItem>
            </ItemWrap>
          </FormRow>
          <FormRow>
            <Label>フリガナ</Label>
            <ItemWrap>
              <InnerItem>{customer.lastNameFurigana}</InnerItem>
              <InnerItem>{customer.firstNameFurigana}</InnerItem>
            </ItemWrap>
          </FormRow>
          <FormRow>
            <Label>携帯番号</Label>
            <Item>{customer.phoneNumber}</Item>
          </FormRow>
          <FormRow>
            <Label>メールアドレス</Label>
            <Item>{customer.email}</Item>
          </FormRow>
          <FormRow>
            <Label>ナンバー</Label>
            <NumberPlateWrap>
              {createNumberPlateArrayFromObject(customer.numberPlate).map((item) => (
                <InnerItem key={item}>{item}</InnerItem>
              ))}
            </NumberPlateWrap>
          </FormRow>
          <FormRow>
            <Label>住所</Label>
            <Item>{customer.customer.address}</Item>
          </FormRow>
        </ContentArea>
      </ContentWrap>
      <FooterArea>
        <CancelWrap>
          <Button type="secondary" onClick={() => navigate("/pit_port/customer")} label="キャンセル" width="160px" />
        </CancelWrap>
      </FooterArea>
      <UserModal isVisible={isModalShow} config={ModalConfig}></UserModal>
    </Board>
  );
};

export default EditCustomer;
