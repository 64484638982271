import styled from "styled-components";

import RoundedButton from "components/RoundedButton";
import SearchForm from "components/SearchForm";

const Area = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0;
`;
const FilterWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
`;
const Text = styled.span`
  font-weight: bold;
  margin-right: 5px;
`;

const UserStatusFilter = {
  all: "all",
  enabled: "enabled",
  unauthorized: "unauthorized",
  disabled: "disabled",
} as const;

const ActionArea = ({
  searchKeyword,
  selectedFilter,
  onSearchChange,
  onFilterButtonClick,
}: {
  searchKeyword: string;
  selectedFilter: PitPortUserDisplayFilterStatus;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFilterButtonClick: (status: PitPortUserDisplayFilterStatus) => void;
}) => {
  return (
    <Area>
      <SearchForm width="350px" value={searchKeyword} placeholder="検索" onChange={onSearchChange} />
      <FilterWrap>
        <Text>絞り込み</Text>
        <RoundedButton
          label="すべて"
          isSelected={selectedFilter === UserStatusFilter.all}
          onClick={() => onFilterButtonClick(UserStatusFilter.all)}
        />
        <RoundedButton
          label="利用中"
          isSelected={selectedFilter === UserStatusFilter.enabled}
          onClick={() => onFilterButtonClick(UserStatusFilter.enabled)}
        />
        <RoundedButton
          label="利用停止"
          isSelected={selectedFilter === UserStatusFilter.disabled}
          onClick={() => onFilterButtonClick(UserStatusFilter.disabled)}
        />
      </FilterWrap>
    </Area>
  );
};

export default ActionArea;
